import React from "react";
import "./styles.css";

const Privacy = () => {
  return (
    <div>
      <section id="privacy" className="page privacy-policy">
        <div className="align-center">
          <header className="section-header">
            <h2>Privacy Policy</h2>
          </header>
        </div>
        <div className="privacy-content">
          <p className="effective-date">Effective Date: 09/26/2024</p>

          <div className="policy-section">
            <p>
              Select Corporation ("Company," "we," "our," or "us") is committed
              to safeguarding the privacy of the individuals who visit our
              website (<a href="https://selectcorp.org">selectcorp.org</a>) and
              interact with us through various channels, including email, SMS,
              and phone communications. This Privacy Policy outlines how we
              collect, use, share, and protect your information when you engage
              with us.
            </p>

            <h3>1. Scope of this Policy</h3>
            <p>
              This Privacy Policy applies to personal data collected through our
              website, mobile communications (including SMS), and other
              interactions with Select Corporation. By using our services,
              visiting our website, or opting in to receive communications from
              us, you consent to the practices outlined in this policy.
            </p>

            <h3>2. Information We Collect</h3>
            <p>We may collect various types of information from you, including:</p>

            <h4>a. Personal Information:</h4>
            <ul>
              <li>Name</li>
              <li>Email address</li>
              <li>Telephone number (including for SMS)</li>
              <li>Business name (if applicable)</li>
              <li>Mailing address</li>
              <li>Other details relevant to our interactions</li>
            </ul>

            <h4>b. Automatically Collected Information:</h4>
            <ul>
              <li>IP address</li>
              <li>Browser type and version</li>
              <li>Time zone settings</li>
              <li>Operating system and platform</li>
              <li>Pages you view on our website</li>
            </ul>

            <h3>3. How We Use Your Information</h3>
            <ul>
              <li>To process and manage inquiries, orders, or requests.</li>
              <li>To send important updates, notifications, and responses via SMS if consented.</li>
              <li>To improve user experience and provide personalized interactions.</li>
              <li>To analyze website traffic, user behavior, and engagement.</li>
              <li>To ensure security and compliance with our policies.</li>
            </ul>

            <h3>4. Sharing of Your Information</h3>
            <h4>a. Service Providers:</h4>
            <p>
              We work with trusted third-party vendors (e.g., hosting providers,
              analytics services) who are bound by confidentiality agreements.
            </p>

            <h4>b. Legal Compliance:</h4>
            <p>
              We may disclose personal information to comply with legal
              obligations, enforce our terms, or protect rights and safety.
            </p>

            <h4>c. Business Transfers:</h4>
            <p>
              In the event of a merger, acquisition, or asset sale, your
              information may be transferred.
            </p>

            <h3>5. SMS Consent</h3>
            <p>
              We may send you SMS messages for service-related communications.
              Your consent is not shared with third parties for marketing.
              You can opt out of SMS at any time by replying “STOP.”
            </p>

            <h3>6. Data Security</h3>
            <p>
              We implement security measures such as encryption and access
              control but cannot guarantee absolute security.
            </p>

            <h3>7. Your Rights and Choices</h3>
            <ul>
              <li>Access your personal data.</li>
              <li>Rectify inaccurate information.</li>
              <li>Request deletion (subject to legal requirements).</li>
              <li>Withdraw SMS consent anytime by replying “STOP.”</li>
            </ul>

            <h3>8. Contact Us</h3>
            <div className="contact-us">
              <p>Select Corporation</p>
              <p>160 W. Carmel Dr., Ste. 101, Carmel, IN 46032</p>
              <p>Email: office@selectcorp.org</p>
              <p>Phone: 425-242-6641</p>
              <p>
                <a href="https://selectcorp.org/privacy">
                  Website Privacy Policy Link
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy;