import React from "react";
import "./styles.css";

const Terms = () => {
  return (
    <div>
      <section id="terms" className="page terms-conditions">
        <div className="align-center">
          <header className="section-header">
            <h2>Select Corporation SMS Terms & Conditions</h2>
          </header>
        </div>
        <div className="terms-content">
          <p className="effective-date">Effective Date: 03/15/2025</p>

          <div className="policy-section">
            <p>
              Select Corporation (“we,” “us,” or “our”) offers a mobile messaging program (the “Program”)
              subject to these SMS Terms & Conditions. By enrolling in or participating in our Program,
              you agree to these terms and conditions.
            </p>

            <h3>1. Program Description</h3>
            <p>
              By opting into our Program, you consent to receive recurring automated promotional and
              personalized marketing text messages (e.g., appointment reminders, load information, service
              updates, dispatch notifications) from Select Corporation at the mobile number you provided.
              Message frequency may vary.
            </p>

            <h3>2. SMS Consent Communication</h3>
            <p>
              The information (Phone Numbers) obtained as part of the SMS consent process will not be shared
              with third parties for marketing purposes.
            </p>

            <h3>3. Types of SMS Communications</h3>
            <p>
              If you have consented to receive text messages from Select Corporation, you may receive
              messages related to the following:
            </p>
            <ul>
              <li>Appointment reminders</li>
              <li>Follow-up messages</li>
              <li>Billing inquiries</li>
              <li>Promotions or offers</li>
            </ul>

            <h3>4. Example Message</h3>
            <p>
              "Hello, this is a friendly reminder of your upcoming delivery appointment at [Location]
              on [Date] at [Time]. Reply STOP to opt out of SMS messaging at any time."
            </p>

            <h3>5. Message Frequency</h3>
            <p>
              Message frequency may vary depending on the type of communication. For example, you may receive
              up to 10 SMS messages per week related to your pickup or delivery appointments, billing, payroll, etc.
            </p>

            <h3>6. Potential Fees for SMS Messaging</h3>
            <p>
              Standard message and data rates may apply, depending on your carrier’s pricing plan.
              These fees may vary if the message is sent domestically or internationally.
            </p>

            <h3>7. Opt-In Method</h3>
            <p>You may opt-in to receive SMS messages from Select Corporation in the following ways:</p>
            <ul>
              <li>Verbally, during a conversation</li>
              <li>By submitting an online form</li>
              <li>By filling out a paper form</li>
            </ul>

            <h3>8. Opt-Out Method</h3>
            <p>
              You can opt out of receiving SMS messages at any time. To do so, simply reply "STOP" to any
              SMS message you receive. Alternatively, you can contact us directly to request removal from
              our messaging list.
            </p>

            <h3>9. Help</h3>
            <p>
              If you are experiencing any issues, you can reply with the keyword "HELP" or contact us directly at:
            </p>
            <div className="contact-us">
              <p>Select Corporation</p>
              <p>160 W. Carmel Dr., Ste. 101, Carmel, IN 46032</p>
              <p>Email: office@selectcorp.org</p>
              <p>Phone: 425-242-6641</p>
              <p>
                <a href="https://selectcorp.org/">Website</a>
              </p>
            </div>

            <h3>10. Standard Messaging Disclosures</h3>
            <ul>
              <li>Message and data rates may apply.</li>
              <li>You can opt-out at any time by texting "STOP."</li>
              <li>For assistance, text "HELP" or visit our <a href="https://selectcorp.org/privacy">Privacy Policy</a> and <a href="https://selectcorp.org/terms">Terms and Conditions</a>.</li>
              <li>Message frequency may vary.</li>
            </ul>

            <h3>11. Changes to Terms</h3>
            <p>
              We reserve the right to modify these SMS Terms & Conditions at any time.
              Any changes will be effective immediately upon posting to our website.
              Your continued participation in the Program constitutes your acceptance of such changes.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Terms;
